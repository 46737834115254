import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import OffersTab from "./OffersTab";
import DetailsTab from "./DetailsTab";
// import VendorList from "../vendorlist/VendorList";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

export default function BasicTabs({
  vendorID,
  tabLocation,
  vendorDetails,
  selectedLocation,
  reloadMethod,
  switchLocation,
}) {
  const [dialogueOpenCounter, setDialogueOpenCounter] = useState(0);
  return (
    <React.Fragment>
      <Box>
        <TabPanel value={tabLocation} index={0}>
          <OffersTab
            vendorID={vendorID}
            vendorDetails={vendorDetails}
            selectedLocation={selectedLocation}
            reloadMethod={reloadMethod}
            switchLocation={switchLocation}
            dialogueOpenCounter={dialogueOpenCounter}
            setDialogueOpenCounter={setDialogueOpenCounter}
          />
        </TabPanel>
        <TabPanel value={tabLocation} index={1}>
          <DetailsTab
            vendorDesc={vendorDetails.desc}
            selectedLocation={selectedLocation}
          />
        </TabPanel>
      </Box>
    </React.Fragment>
  );
}

import React from "react";
import axios from 'axios'
import { theme } from "./config/theme";
import Main from "./Main";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import { Construction, ConstructionRounded } from "@mui/icons-material";

import "./styles/main.css";

function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={theme}>
        <Router>
          <Main />
        </Router>
      </ThemeProvider>
    </React.Fragment>
  );
}

export default App;

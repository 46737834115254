import React from "react"
import HomeIcon from "@mui/icons-material/Home"
import HomePage from "../pages/HomePage"
import SecondPage from "../pages/home/SecondPage"
import VendorListPage from "../pages/VendorListPage"
import VendorDetailsPage from "../pages/VendorDetailsPage"

export const routes = [
  {
    path: "/",
    name: "Home",
    component: <HomePage />,
    icon: <HomeIcon />
  },
  {
    path: "/second-page",
    name: "Second Page",
    component: <SecondPage />,
    icon: <HomeIcon />
  },
  {
    path: "/second-page/:id",
    name: "Second Page",
    component: <SecondPage />,
    icon: <HomeIcon />
  },
  {
    path: "/vendorlist/:cat/:user/:city",
    name: "Vendor List",
    component: <VendorListPage />,
    icon: <HomeIcon />
  },
  {
    path: "/vendordetails/:id/:user",
    name: "Vendor Details",
    component: <VendorDetailsPage />,
    icon: <HomeIcon />
  }
  // {
  //   path: "/menu",
  //   name: "Menu",
  //   component: <Menu />,
  //   icon: <MenuBookIcon />
  // },
  // {
  //   path: "/menu",
  //   name: "Private Menu",
  //   component: <Menu />,
  //   icon: <MenuBookIcon />,
  //   is_private: true
  // },
  // {
  //   path: "/menu",
  //   name: "Private Menu",
  //   component: <Menu />,
  //   icon: <MenuBookIcon />,
  //   is_private: true,
  //   user_type: "vendor"
  // },
  // {
  //   path: "/menu",
  //   name: "Admin Private Menu",
  //   component: Menu,
  //   icon: <MenuBookIcon />,
  //   is_private: true,
  //   user_type: "admin"
  // }
]

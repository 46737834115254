import {
  Button,
  Dialog,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MAINAPIS from "../../../helper/apiCalls";
import parse from "html-react-parser";
import DeliveryConfirmationDialogue from "./DeliveryConfirmationDialogue";
import { AppContext } from "../../../contexts/AppContext";

const VoucherDialogue = ({
  open,
  handleClose,
  voucher,
  logo,
  category,
  vendorName,
  vendorId
}) => {
  const appContext = useContext(AppContext);
  // States
  const [enteredPin, setEnteredPin] = useState("");
  const [pinInputSection, setPinInputSection] = useState(false);
  const [refCodeDisplay, setRefCodeDisplay] = useState(false);
  const [pinFieldEnabled, setPinFieldEnabled] = useState(true);
  const [deliveryState, setDeliveryState] = useState(false);
  const [dcOpen, setDcOpen] = useState(false);
  const [refCode, setRefCode] = useState("");
  const [SnackbarOpen, setSnackbarOpen] = useState(false);
  const [SBMessage, setSBMessage] = useState("");

  const params = useParams();
  const voucherBackground = () => {
    if (pinInputSection) return "#707070";
    else return category.color;
  };
  // Effects
  useEffect(() => {
    if (appContext.selectedOffer == 4) {
      setDeliveryState(true);
      console.log("setting delivery state as true");
    }
  }, []);
  function initRedmpemption() {
    if (deliveryState) setDcOpen(true);
    else setPinInputSection(true);
  }
  const openSnackbar = () => {
    setSnackbarOpen(true);
  };
  const handleSBClose = () => {
    setSnackbarOpen(false);
  };
  const dcHandleClose = () => {
    setDcOpen(false);
  };
  const handlePinChange = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    if (value.length <= 4) setEnteredPin(value);
    if (value.length === 4) redemptionCall(value);
  };
  async function redemptionCall(pin) {
    const redemptionData = {
      voucherId: voucher.vc_ID,
      vendorId: vendorId.sp_ID,
      userId: params.user,
      savings: voucher.savings,
      pin: pin,
    };
    setPinFieldEnabled(false);
    console.log(redemptionData);
    const { data: result = {} } = await postRedemption(redemptionData);
    console.log(result);
    if (result.status !== "successful") {
      setSBMessage(result.desc);
      openSnackbar();
      setPinFieldEnabled(true);
    } else {
      setPinFieldEnabled(true);
      setRefCodeDisplay(true);
      setRefCode(result.ref_code);
    }
  }

  const postRedemption = (redemptionData) => {
    try {
      const result = MAINAPIS.postRedemption2(redemptionData);
      return result;
    } catch (error) {
      return [];
    }
  };
  function resetDialogue() {
    // if (refCodeDisplay) {
    //   window.location.reload(false);
    // }
    setTimeout(() => {
      setSnackbarOpen(false);
      setEnteredPin("");
      setPinInputSection(false);
      setRefCodeDisplay(false);
      setDcOpen(false);
      setRefCode("");
      setPinFieldEnabled(true);
      handleClose();
    }, 500);
  }
  return (
    <Dialog
      onClose={resetDialogue}
      open={open}
      fullWidth={true}
      maxWidth={"md"}
    >
      <Grid
        container
        className="voucherContainer"
        style={{ backgroundColor: voucherBackground() }}
      >
        <Grid item xs={12} className="voucherHeaderContainer">
          <Typography variant="h6">Vouch365</Typography>
          <button className="voucherCloseButton" onClick={resetDialogue}>
            X
          </button>
        </Grid>
        <Grid
          item
          xs={12}
          className={
            !pinInputSection && !dcOpen
              ? "voucherBodyContainer"
              : "voucherBodyContainer removePadding"
          }
        >
          <img src={logo} alt="VendorLogo" className="voucherLogoImage border-radius-10" />
          {!pinInputSection && !dcOpen && (
            <p>{voucher.desc && parse(voucher.desc)}</p>
          )}
          <p>Customer Name: Testing Account</p>
          <p>Estimated Savings: {voucher.savings}</p>

          {!pinInputSection && !dcOpen && (
            <button className="voucherRedeemButton" onClick={initRedmpemption}>
              Redeem
            </button>
          )}
        </Grid>
        {pinInputSection && !refCodeDisplay && (
          <React.Fragment>
            <Grid item xs={12} className="pinInputTextFieldContainer">
              <TextField
                className="pinInputTextField"
                inputProps={{
                  autoFocus: true,
                  style: { width: 150, textAlign: "center", fontSize: 40 },
                }}
                size="small"
                color="secondary"
                value={enteredPin}
                type="number"
                onChange={handlePinChange}
                disabled={!pinFieldEnabled}
              />
            </Grid>
            <Grid item xs={12} className="voucherFooterContainer">
              <p>Please Enter Service Provider Pin</p>
            </Grid>
          </React.Fragment>
        )}
        {dcOpen && !refCodeDisplay && (
          <React.Fragment>
            <Grid
              container
              item
              xs={12}
              className="deliverConfirmationContainer"
            >
              <Grid item xs={12}>
                <h2>Confirmation</h2>
                <p>Do you want to redeem this voucher?</p>
                <p>No PIN will be required.</p>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="large"
                  color="error"
                  className="confirmationButton"
                  onClick={() => {
                    redemptionCall(8365);
                  }}
                >
                  Yes
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  size="large"
                  color="error"
                  className="confirmationButton"
                  onClick={dcHandleClose}
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
        {refCodeDisplay && (
          <React.Fragment>
            <p className="vcRefCodeHeader">
              <b>Redemption Succesful</b>
            </p>
            <Grid item xs={12} className="voucherRefCodeContainer">
              {deliveryState ? (
              <p>
                Please call <b>{vendorName}</b> and tell them your <b>Reference Number</b> to avail the offer
              </p>) : (
              <p>
                Please show this screen to <b>{vendorName}</b> representative,
                so they can verify the <b>Reference Number</b>
              </p>)}
              <p>
                <b>{refCode}</b>
              </p>
            </Grid>
          </React.Fragment>
        )}
        <Grid item xs={12} className="voucherFooterContainer">
          <p>
            Offers are subject to Rules of Use <br />
            <a href="https://vouch365.com/rules-of-use/" target="_blank">Review Rules of Use</a>
          </p>
        </Grid>
      </Grid>
      <Snackbar
        open={SnackbarOpen}
        autoHideDuration={3000}
        message={SBMessage}
        onClose={handleSBClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      />
      {/* <DeliveryConfirmationDialogue open={dcOpen} handleClose={dcHandleClose} /> */}
    </Dialog>
  );
};

export default VoucherDialogue;

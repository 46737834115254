import axios from "axios";
// const api_key = window.localStorage.getItem("apiKey")

const api = axios.create({
  baseURL: `https://efuserver.vouch365.xyz/ReactUIhandler/`,
  // headers: {
  //   Authorization: `Bearer ${api_key}`
  // }
});

const MAINAPIS = {
  async getSlides(userId, city) {
    const returnData = await api.get(`sliders/${userId}/${city}`);
    return returnData.data;
  },
  async getCategories() {
    const returnData = await api.get("categories");
    return returnData.data;
  },
  async getVendorList(userId, city, category, offertype) {
    const returnData = await api.get(
      `vendorlist/${userId}/${city}/${category}/${offertype}`
    );
    return returnData.data;
  },
  async getVendorDetails(userId, vendorId) {
    return api.get(`vendordetails/${userId}}/${vendorId}`);
    // return returnData.data;
  },
  async postRedemption(redemptionData) {
    const returnData = await api.post("redemption", redemptionData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    return returnData.data;
  },
  async postRedemption2(redemptionData) {
    return api.post('redemption', redemptionData, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    })
  },
};

export default MAINAPIS;

import { Button, ButtonGroup, Dialog, Grid, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";

const CategoryDialogue = ({ open, handleClose, categories, selectedCategory, urlParams }) => {
  const buttons = [
    categories.map((category, i) => (
      <a
        className="reset-a"
        href={`/vendorlist/${category.id}/${urlParams.user}/${urlParams.city}`}
        key={category.id}>
        <Button
          key={category.id}
          disabled={selectedCategory && (category.id === selectedCategory.id) ? true : false}
        >
          {category.name}
        </Button>

      </a>
    ))
  ];
  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
      <Grid
        container
        className="voucherContainer"
      >
        <Grid item xs={12} className="categoryDialogueHeaderContainer">
          <Typography variant="h6">Select Category</Typography>
          <button className="voucherCloseButton" onClick={handleClose}>
            X
          </button>
        </Grid>
        <Grid item xs={12} className="categoryDialogueBodyContainer">
          <Grid container
            className="categoryDialogueListContainer">

            <ButtonGroup
              orientation="vertical"
              aria-label="vertical contained button group"
              variant="text"
            >
              {buttons}
            </ButtonGroup>
          </Grid>
        </Grid>

      </Grid>
    </Dialog>
  );
};

export default CategoryDialogue;

import { Grid, Paper } from "@mui/material";
import React, { useContext } from "react";
import ArrowRight from "@mui/icons-material/ArrowRight";
import { Image } from "react-image-and-background-image-fade";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../../contexts/AppContext";

const VendorListElement = ({ vendor, userId, offer }) => {
  const appContext = useContext(AppContext);
  const navigate = useNavigate();
  const goToVendor = () => {
    appContext.setSelectedOffer(offer);
    console.log(appContext.selectedOffer);
    navigate(`/vendordetails/${vendor.sp_ID}/${userId}`);
  };

  return (
    <React.Fragment>
      <Paper onClick={goToVendor} className="vendorListElementContainer">
        <Grid container>
          <Grid item xs={3} className="vendorListElementLogo">
            {/* <img src={vendor.logo} width={"100%"} sx={{ pl: 2 }} /> */}

            <Image
              src={vendor.logo}
              width="256px"
              height="256px"
              alt={vendor.name}
              title={vendor.name}
              className="reactLazyLoaderBGimageSetter border-radius-10"
              isResponsive
              lazyLoad
            />
          </Grid>
          <Grid item xs={8} className="vendorListElementMText">
            <Grid
              container
              className="vendorListElementMGrid"
              direction={"column"}
              justifyContent={"center"}
              alignItems={"stretch"}
            >
              <Grid item>
                <h4>{vendor.name}</h4>
              </Grid>
              <Grid item>
                <Grid container>
                  <Grid item xs={1}>
                    <Image
                      src={vendor.categoryLogo}
                      width="80%"
                      height="80%"
                      alt={vendor.name}
                      title={vendor.name}
                      className="reactLazyLoaderBGimageSetter"
                      isResponsive
                      lazyLoad
                    />
                    {/* <img src={vendor.categoryLogo} width={"25"} /> */}
                  </Grid>
                  <Grid item xs={11} className="vendorListElementLocality">
                    <span className="vendorListLocality">
                      <small>{vendor.locality}</small>
                    </span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1}>
            <Grid
              container
              className="vendorListElementMGrid"
              direction={"column"}
              justifyContent={"space-around"}
              alignItems={"stretch"}
            >
              <Grid item>
                <ArrowRight />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};

export default VendorListElement;

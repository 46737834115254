import React, {useContext} from "react";

import { AppContext } from "../contexts/AppContext"

import { FormControl, MenuItem, Select } from "@mui/material";

const CitySelect = () => {
  const context = useContext(AppContext);

  const handleCityChange = (event) => {
    context.setSelectedCity(event.target.value);
  };
  return (
    <FormControl sx={{ m: 1, minWidth: 150 }}>
      <Select
        value={context.selectedCity}
        onChange={handleCityChange}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem value="karachi" selected>
          Karachi
        </MenuItem>
        <MenuItem value="lahore">Lahore</MenuItem>
        <MenuItem value="islamabad">Islamabad</MenuItem>
        <MenuItem value="london">London</MenuItem>
      </Select>
    </FormControl>
  );
};

export default CitySelect;

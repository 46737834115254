import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import VendorPageNav from "./components/vendordetails/VendorPageNav";
import VendorPageTab from "./components/vendordetails/VendorPageTab";
import { Grid } from "@mui/material";
import MAINAPIS from "../helper/apiCalls";

const VendorListPage = () => {
  // States
  const [tabLocation, setTabLocation] = useState(0);
  const [vendorLocations, setLocationData] = useState([]);
  const [favouritesBool, setFavouritesBool] = useState();
  const [vendorDetails, setVendorDetails] = useState({});
  const [selectedLocation, setSelectedLocation] = useState({
    sp_ID: "0000",
    locality: "locality",
    city: "city",
    latitude: "24.881664",
    longitude: "67.062934",
    locations: "...",
  });
  const categoryId = vendorDetails.categoryData
    ? vendorDetails.categoryData.id
    : 1;
  const params = useParams();
  const handleTabChange = (event, newValue) => {
    setTabLocation(newValue);
  };
  // Effects
  useEffect(() => {
    getVendorDetails();
    // setVendorDetails(vendorData);
  }, []);
  useEffect(() => {
    if (!selectedLocation || selectedLocation.sp_ID == "0000") {
      assignLocData();
     
    }
  }, [vendorDetails]);
  // Fetching Vendor List to set State
  const getVendorDetails = async () => {
    try {
      console.log("reloading vendor");
      const result = await MAINAPIS.getVendorDetails(params.user, params.id);
      const vendorDetails = result.data.data;
      setFavouritesBool(vendorDetails.favourite);
      setLocationData(vendorDetails.locationData);
      setVendorDetails(vendorDetails);
    } catch (error) {
      return [];
    }
  };
  function assignLocData() {
    setFavouritesBool(vendorDetails.favourite);
    setLocationData(vendorDetails.locationData);
    setSelectedLocation(
      vendorLocations && vendorLocations.find((loc) => loc.sp_ID === params.id)
    );
  }
  function assignLocWrtID(id) {
    setSelectedLocation(
      vendorLocations && vendorLocations.find((loc) => loc.sp_ID === id)
    );
  }
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12}>
          <VendorPageNav
            title={vendorDetails.name}
            tabLocation={tabLocation}
            handleTabChange={handleTabChange}
            favCheck={favouritesBool}
            categoryID={categoryId}
            cityName={vendorDetails.city}
            userID={params.user}
          />
        </Grid>
        <Grid item xs={12} className="vendorDetailsContainer">
          <VendorPageTab
            tabLocation={tabLocation}
            vendorDetails={vendorDetails}
            selectedLocation={selectedLocation}
            vendorID={params.id}
            reloadMethod={getVendorDetails}
            switchLocation={assignLocWrtID}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default VendorListPage;

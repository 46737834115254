import { Grid, Paper, Snackbar } from "@mui/material";
import React, { useState } from "react";
import { Image } from "react-image-and-background-image-fade";
import Redeemed from "@mui/icons-material/CheckBox";

const VoucherListElement = ({
  voucher,
  openDialogue,
  setVoucher,
  category,
}) => {
  const [SnackbarOpen, setSnackbarOpen] = useState(false);
  const [SBMessage, setSBMessage] = useState("");
  const openSnackbar = () => {
    setSnackbarOpen(true);
  };
  const handleSBClose = () => {
    setSnackbarOpen(false);
  };
  const handleClickOpen = () => {
    if (!voucher.redeemed) {
      setVoucher(voucher);
      openDialogue();
    } else {
      openSnackbar();
      setSBMessage("Voucher already redeemed");
    }
  };
  return (
    <React.Fragment>
      <Paper>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          className="voucherListContainer"
          onClick={handleClickOpen}
        >
          <Grid item xs={3}>
            {voucher.redeemed ? (
              <Redeemed sx={{ fontSize: "4em", color:"#5a5a5a" }} className="voucherIcon"/>
            ) : (
              <img src={category.logo} alt="" className="voucherIcon" />
            )}
          </Grid>
          <Grid item xs={8}>
            <Grid container direction="column" className="voucherTextContainer">
              <Grid item xs={12}>
                <h4 className="voucherTitle">{voucher.name}</h4>
              </Grid>
              <Grid item xs={12}>
                {voucher.redeemed ? (
                  <span><small>Already Redeemed</small></span>
                ) : (
                  <span><small>{"Valid Until " + voucher.expdate}</small></span>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <Snackbar
        open={SnackbarOpen}
        autoHideDuration={3000}
        message={SBMessage}
        onClose={handleSBClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
      />
    </React.Fragment>
  );
};

export default VoucherListElement;

import React, { useState, useEffect, useContext } from "react"
// import Carousel from 'react-material-ui-carousel'
import MAINAPIS from "../helper/apiCalls"
import { AppContext } from "../contexts/AppContext"
import TopCarousel from "./components/home/TopCarousel"
import BottomCarousel from "./components/home/BottomCarousel"
import CategoryBar from "./components/home/CategoryBar"
import SliderTag from "./components/home/SliderTag"
import Navigation from "../app-bar/Navigation"


const HomePage = () => {
    // States
    const appContext = useContext(AppContext)
    const [sliders, setSliders] = useState(() => ({}))
    // Effects
    useEffect(async () => {
        getSliders()
    }, [])
    useEffect(async () => {
        getSliders()
    }, [appContext.selectedCity])
    // Fetching Sliders to set State
    const getSliders = async () => {
        try {
            const result = await MAINAPIS.getSlides(2, appContext.selectedCity)
            setSliders(result)
        } catch (error) {
            return setSliders([])
        }
    }
    // Fetching Categories
    const categoriesData = [
        {
            id: 1,
            name: "Food & Drinks",
            category_color: "#F15B74",
            retail: "https://v3testing.vouch365.mobi/storage/imgs/category_icons/retail/new/1.png"
        },
        {
            id: 2,
            name: "Salon & Spa",
            category_color: "#E362A4",
            retail:
                "https://v3testing.vouch365.mobi/storage/imgs/category_icons/retail/new/2.png"
        },
        {
            id: 3,
            name: "Leisure & Attraction",
            category_color: "#7ECBB7",
            retail:
                "https://v3testing.vouch365.mobi/storage/imgs/category_icons/retail/new/3.png"
        },
        {
            id: 4,
            name: "Services & Retail",
            category_color: "#FFCE72",
            retail:
                "https://v3testing.vouch365.mobi/storage/imgs/category_icons/retail/new/4.png"
        },
        {
            id: 5,
            name: "Health & Fitness",
            category_color: "#B38BC0",
            retail:
                "https://v3testing.vouch365.mobi/storage/imgs/category_icons/retail/new/5.png"
        },
        {
            id: 6,
            name: "Travel & Tourism",
            category_color: "#79CACE",
            retail:
                "https://v3testing.vouch365.mobi/storage/imgs/category_icons/retail/new/6.png"
        }
    ]
    return (
        <React.Fragment>
            <Navigation />
            <TopCarousel slide={sliders} />
            <CategoryBar categories={categoriesData} />
            <SliderTag text="more to enjoy"/>
            <BottomCarousel slide={sliders.new} type="new" />
            <SliderTag text="featured"/>
            <BottomCarousel slide={sliders.featured} type="featured" />
        </React.Fragment>
    )
}
export default HomePage

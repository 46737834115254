import { LocationOn } from '@mui/icons-material'
import { Grid, Typography } from '@mui/material'
import React from 'react'

const LocationData = ({ selectedLocation }) => {
    // console.log(selectedLocation)
    return (
        <Grid container>
            <Grid item xs={2} className="locationPinIcon">
                <LocationOn />
            </Grid>
            <Grid item xs={10} >
                <Typography variant="h6">

                    {selectedLocation && selectedLocation.locality}

                </Typography>
            </Grid>
        </Grid>
    )
}

export default LocationData
import React, { useContext } from "react"
import { makeStyles } from "@mui/styles"
import { AppContext } from "../contexts/AppContext"

import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import NavigationDrawer from "./NavigationDrawer"
import Search from "./Search"
import CitySelect from "./CitySelect"


export default function Navigation() {
  return (
    <div>
      <NavigationDrawer />
      <AppBar position="fixed" color="secondary">
        <Toolbar>
          <CitySelect />
          <Typography variant="h6" className="widthHundredPC"></Typography>
          <Search />
        </Toolbar>
      </AppBar>
    </div>
  )
}

import React, { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { AppProvider } from "../contexts/AppContext"

function AppContainer({ children }) {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [selectedCity, setSelectedCity] = useState("karachi")
  const [selectedCategory, setSelectedCategory] = useState("")
  const [selectedOffer, setSelectedOffer] = useState("")
  const [applicationKey, setApplicationKey] = useState(null)
  const location = useLocation()
  useEffect(() => {
    const query = new URLSearchParams(location.search)
    setApplicationKey(query.get("api_key"))
    const mystorage = window.localStorage
    mystorage.setItem("apiKey", applicationKey)
  }, [])

  function toggleDrawer() {
    setIsDrawerOpen(!isDrawerOpen)
  }
  return (
    <AppProvider
      value={{
        isDrawerOpen,
        toggleDrawer,
        selectedCity,
        setSelectedCity,
        selectedCategory,
        setSelectedCategory,
        applicationKey,
        selectedOffer,
        setSelectedOffer,
      }}
    >
      {children}
    </AppProvider>
  )
}

export default AppContainer

import { Grid } from '@mui/material'
import React from 'react'

const DetailsTab = ({ vendorDesc, selectedLocation }) => {
  return (
    <React.Fragment>
      <Grid container className='vendorAddressContainer' >
        <Grid item>
          <p className='detailsCityName'> {selectedLocation.city}</p>
          <p className='detailsVendorAddress'> {selectedLocation.locations}</p>
          <hr />

          <p className='detailsVendorDesc'> {vendorDesc}</p>
        </Grid>
        <Grid item className="detailsTabMap">
        
        </Grid>
      </Grid>
    </React.Fragment>
  )
}

export default DetailsTab
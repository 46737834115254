import React from "react";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import BackArrow from "@mui/icons-material/ArrowBackIosNew";
import ArrowDropDown from "@mui/icons-material/ArrowDropDown";
import { Link } from "react-router-dom";
import { Grid, Skeleton } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    paddingTop: "10% !important",
    paddingBottom: 0,
  },
  subTitle: {
    flexGrow: 1,
    paddingTop: "1% !important",
    paddingBottom: 0,
  },
  middleGridContainer: {
    height: "100%",
    width: "100%",
  },
}));

export default function VendorListNav({
  categoryClicked,
  categoryDialogue,
  urlParams,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <AppBar position="fixed" color="secondary" elevation={0}>
        <Toolbar>
          {/* <Link to="/" className="basicLink">
            <BackArrow />
          </Link> */}
          <Grid
            container
            className={classes.middleGridContainer}
            justifyContent="center"
          >
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography
                    variant="h5"
                    className={classes.title}
                    sx={{
                      textTransform: "capitalize",
                      textAlign: "center",
                    }}
                    onClick={categoryDialogue}
                  >
                    {categoryClicked ? (
                      <React.Fragment>
                        {categoryClicked.name}
                        <ArrowDropDown />
                      </React.Fragment>
                    ) : (
                      <Skeleton
                        variant="Rectangle"
                        animation="wave"
                        height="26px"
                        width="200%"
                        style={{ marginTop: "10px", marginBottom: "5px", marginLeft: "-50%" }}
                      />
                    )}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    className={classes.subTitle}
                    sx={{
                      textTransform: "capitalize",
                      textAlign: "center",
                      p: 2,
                    }}
                  >
                    in {urlParams.city}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </div>
  );
}

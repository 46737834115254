import { Dialog, Divider, Grid, Typography } from "@mui/material";
import CallPhone from "@mui/icons-material/Call";
import React from "react";

const CallDialogue = ({ open, handleClose, vendorName, vendorNumber }) => {
  var placeCall = (number) => {
    window.open(`tel:${number}`, "_self");
  };
  // function handlePlaceCall(vendorNumber) {
  //   // trimming white spaces
  //   const number = vendorNumber;
  //   // const number = vendorNumber.replace(/\s/g, " ").trim();
  //   window.open(`tel:${number}`, "_self");
  // }
  return (
    <Dialog onClose={handleClose} open={open} fullWidth={true} maxWidth={"md"}>
      <Grid container className="voucherContainer">
        <Grid item xs={12} className="categoryDialogueHeaderContainer">
          <Typography variant="h6">Contact</Typography>
          <button className="voucherCloseButton" onClick={handleClose}>
            X
          </button>
        </Grid>
        <Grid item xs={12} className="categoryDialogueBodyContainer">
          <Grid container className="categoryDialogueListContainer">
            <Grid item xs={8} className="dialogueCallText">
              Call {vendorName} <br />
              at {vendorNumber}
            </Grid>
            <Grid item xs={4} className="dialogueCallButton">
              <CallPhone onClick={() => placeCall(vendorNumber)} />
            </Grid>
            <Grid item xs={12} className="callListDivider">
              <Divider />
            </Grid>
            <Grid item xs={8} className="dialogueCallText">
              Call Vouch365 Support <br />
              at 021-111486824
            </Grid>
            <Grid item xs={4} className="dialogueCallButton">
              <CallPhone onClick={() => placeCall("021111486824")} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default CallDialogue;
